@use "src/_secret.scss";
@use "src/_rpg.scss";
@use "src/_ioze.scss";
@use "src/_utils.scss";
@use "src/_theme.scss";
@use "src/_pool.scss";
@import url('https://fonts.googleapis.com/css2?family=Otomanopee+One&family=Qahiri&family=Secular+One&display=swap');

$lightestgrey: #cfcfcf;
$lightgrey: #767676;
$almostwhite: #f4f4f4;
$hoverTextColor: $almostwhite;
$textColor: $lightestgrey;
$buttonBG: rgba(0,0,0,1);

$hoverBackground: $buttonBG;





$bg: #000000;
$highlightColor: $almostwhite;
body {
  background: $bg;
  @include theme.mainFont;
}

.App {


  header {
    $h: 128px;
    position: fixed;
    top:0;
    left: 0;
    right:0;
    bottom: calc(100vh - #{$h});
    a img {
      position: absolute;
      left: ($h - $h/2)/2;
      height: $h/2;
      top: ($h - $h/2)/2;
      filter: drop-shadow(0 0 4px white);
      cursor: pointer;
    }

    .header-title {
      @include theme.titleFont;
      position: absolute;
      left: $h/2 + ($h - $h/2);
      top: 0;
      font-size: $h/2;
      line-height: $h;
      color: $textColor;
      letter-spacing: 4px;

      transform: scale(1) translateX(0);
      transition: 0.2s transform ease-in-out;

    }



  }

}


.farmland {
  width: 100%;
  box-sizing: border-box;
  padding: 128px 64px 64px;
  height: 100vh;
  overflow: hidden;
}

.pool {
  $w: 100%;
  box-sizing: border-box;
  display: inline-block;
  border: 2px solid $textColor;

  width: $w;
  
  margin: 0;
  padding: 16px;
  box-sizing: border-box;

  color: $textColor;
  border-radius: 16px;

  

  &__name {
    img {
      height: 24px;
      margin-left: 4px;
      position: relative;
      top: 6px;
    }
  }

  .arrows {
    margin-right: 8px;
    margin-left: 8px;
  }

  &__textName {
    font-size: 32px;
    @include theme.titleFont;
  }


  button {
    outline: none;
    border: 2px solid $textColor;
    background: $buttonBG;
    color: $textColor;
    padding: 4px 8px;
    margin: 2px 4px;
    cursor: pointer;
    transform: scale(1);
    border-radius: 4px;

    transition: 0.2s transform ease-in-out, 0.2s background ease-in-out, 0.2s color ease-in-out;
    &:hover {
      background: $hoverBackground;
      color: $hoverTextColor;
      transform: scale(1.1);

    }
  }
}

.pool__controls {
  text-align: right;
}


.faq {
  display: none;
}


.metamask-btn {
  position: fixed;
  top: 16px;
  right: 16px;
  outline: none;
  border: 2px solid $textColor;
  @include theme.mainFont;
  color: $textColor;
  padding: 4px 8px;
  margin: 2px 4px;
  cursor: pointer;
  transform: scale(1);
  border-radius: 4px;
  font-size: 20px;

  transition: 0.2s transform ease-in-out, 0.2s background ease-in-out, 0.2s color ease-in-out;
  &:hover {
    background: $hoverBackground;
    color: $hoverTextColor;
    transform: scale(1.1);

  }

}

.btn {

  outline: none;
  border: 2px solid $textColor;
  background: none;
  color: $textColor;
  padding: 4px 8px;
  margin: 2px 4px;
  cursor: pointer;
  transform: scale(1);
  border-radius: 4px;

  transition: 0.2s transform ease-in-out, 0.2s background ease-in-out, 0.2s color ease-in-out;
  &:hover {
    background: $hoverBackground;
    color: $hoverTextColor;
    transform: scale(1.1);

  }


}



.theInput {
  &.theInput--false {
    display: none;
  }
  &.theInput--true {
    display: block;
  }

  $w: 512px;


  position: fixed;
  text-align: center;
  background: rgba(0,0,0,0.9);
  top: 10vh;
  left: 10vw;
  right: 10vw; 
  bottom: 10vh;

  $inputH: 48px;
  input {
    @include theme.mainFont;
    display: block;
     outline: none;
    border: 2px solid $textColor;
    background: none;
    color: $textColor;
    padding: 0 8px;
    width: $w;
    

    $h: $inputH;
    height: $h;
    font-size: 32px;
    line-height: 48px;
    box-sizing: border-box;

    margin: calc(20vh - #{$h}/2) auto;



    cursor: pointer;
    transform: scale(1);
    border-radius: 4px;

  }

  .btn--back {
    position: fixed;
    top: calc(50vh - #{$inputH} - 16px);
    left: calc(50vw - #{$w}/2);
  }

  .btn--approve, .btn--withdraw {
    position: fixed;
    top: calc(50vh + #{$inputH}/2 + 16px);
    right: calc(50vw - #{$w}/2);
  }

  .btn--deposit {
    position: fixed;
    top: calc(50vh + #{$inputH}/2 + 60px);
    right: calc(50vw - #{$w}/2);
  }

  &__balance {
    position: fixed;
    top: calc(50vh - #{$inputH} - 16px);
    right: calc(50vw - #{$w}/2);
    color: $textColor;

  }

  &__allowance {
    position: fixed;
    top: calc(50vh - #{$inputH} - 48px);
    right: calc(50vw - #{$w}/2);
    color: $textColor;

  }


  &.INTENT__theInput--add {
    .btn--approve, .btn--deposit {
      display: block;
    }
    .btn--withdraw {
      display: none;
    }
  }
  &.INTENT__theInput--remove {
    .btn--approve, .btn--deposit {
      display: none;
    }
    .btn--withdraw {
      display: block;
    }
  }





}




h1 {
  @include theme.titleFont;
  font-size: 64px;
}

@include secret.Secret;
@include rpg.Rpg;
@include ioze.Ioze(64px);
@include ioze.IozeSelect(128px);

.theInput {
  h1 {
    text-align: left;
  }
  .ioze__word {
    
  }
}


.alphabet-btn {
  position: fixed;
  bottom: 16px;
  left: 16px;
  color: white;
  cursor: pointer;
  &--false {
    display: none;
  }
}

.dash {
  position: fixed;
  background: rgba(0,0,0,0.8);
  color: white;
  font-size: 16px;
    line-height: 32px;
    text-align: right;
  &--top-right {
    top: 16px;
    right: 16px;
    height: 32px;
    width: 64px;

    
    
    padding: 2px 8px;
    border-radius: 4px;
    
    img, span {
      height: 32px;
      vertical-align: top;
    }
    img {
      margin-left: 8px;
    }
  }
  &--right {
    top: 64px;
    right: 16px;
    height: 32px;
    width: 64px;

    
    
    padding: 2px 8px;
    border-radius: 4px;
    
    img, span {
      height: 32px;
      vertical-align: top;
    }
    img {
      margin-left: 8px;
    }
  }
}


.App--false {
  .dash {
    display: none;
  }
}

.inventory {
  position: fixed;
  bottom: 16px;
  right: 16px;
  background: rgba(scale-color(theme.$blue, $lightness: -80%),0.8);
  border: 1px solid theme.$pink;
  width: 256px;
  padding: 8px;

  h3 {
    @include theme.gradGP;
    display: inline-block;
    width: 100%;
    font-size: 24px;
    margin: 2px 8px;

  }

  .inventory__slot {
    width: 64px;
    height: 64px;
    box-shadow: inset 0px 0px 4px rgba(0,0,0,1);
    display: inline-block;
    margin: 2px 4px;
    cursor: pointer;

    position: relative;
    background: linear-gradient(120deg, theme.$pink, theme.$blue);

    &:hover {
      background: linear-gradient(120deg, theme.$blue, theme.$green);
    }
    img {
      height: 90%;
      position: absolute;
      top: 5%;
      left: 0;
      filter: drop-shadow(2px 2px 4px rgba(0,0,0,1));
      
      &.lore-0 {
        
      }
    }
    span {
      position: absolute;
      background: rgba(0,0,0,0.2);
      color: white;
            border-radius: 8px;
      padding: 1px 8px;
      bottom: 0;
      right: 0;
    }
  }
}


.travel-true {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  font-size: 32px;
  line-height: 64px;
  color: white;
  background-color: black;

}
.travel-false {
  display: none;
}

.lore-focus--true {
  display: block;
}
.lore-focus--false {
  display: none;
}

.lore-focus {
  position: fixed;
  color: black;
  z-index: 99999;
  padding: 16px;
  background: rgba(100,100,100,0.95);
  max-width: 720px;
  top:0;
  height: 100vh;
  box-sizing: border-box;

  overflow-y: scroll;

  @include utils.scrolly;
  h2 {
    font-size: 48px;
    line-height: 64px;
    span {
      vertical-align: middle;
      margin-left: 16px;
    }
    
  }

  img {
    height: 64px;
    vertical-align: middle;
  }

  p.lore-desc {
    font-size: 32px;
  }
  p {
    font-size: 18px;
  }
}

.player {
  color: white;
  position: fixed;
  bottom: 16px;
  left: 16px;
  button {
    color:white;
    cursor: pointer;
    @include utils.buttonSizing(16px);
    @include utils.buttonStyling;
  }
}


.pub--false {
  display: none;
}


.pub {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 320px;
  right: 320px;
  background: black;
  padding: 16px;

  overflow-y: scroll;
  @include utils.scrolly;

  h1, h2 {
    @include theme.gradBP;
    display: inline-block;
    
    @include theme.mainFont;
  }
  h1 {
    margin: 16px 0;
  }

  h2 {
    margin: 2px 0;
  }

  h3 {
     @include theme.gradGP;
    display: inline-block;
    margin: 2px 0;
    @include theme.mainFont;
    cursor: pointer;
    &:hover {
      @include theme.gradPG;
    }
  }

  .info-collapse {
 
    p {
        transform: scaleY(1);
        transition: 0.2s transform ease-in-out;
      }
    &--false {
      height: 0px;
      p {
        transform: scaleY(0);
      }
    }

    &--true {
      
    }
  }

  p {
    color: white;
  }
}

@include pool.Pool(100%);

button {
  @include utils.buttonSizing(16px);
  @include utils.buttonStyling;
}




// ---------------------------------------------------------------------------------------
// Input Overlay --------------------------------------------------------------------------

$inputOverlayButtonSize: 20px;
$inputOverlayButtonHeight: 7*$inputOverlayButtonSize/4;
$inputOverlayGutter: 16px;
.btn {
  @include utils.buttonSizing($inputOverlayButtonSize);
  @include utils.buttonStyling;
}


.theInput {
  &.TOGGLE__theInput--false {
    display: none;
  }
  &.TOGGLE__theInput--true {
    display: block;
  }

  $w: 600px;

  z-index: 12;
  position: fixed;
  text-align: center;
  background: rgba(0,0,0,0.8);
  top: 0;
  left: 0;
  right: 0; 
  bottom: 0;

  $inputH: $w/6.5;
  $like8px: $w/39;
  input {
    display: block;
     outline: none;
    border: min($like8px/4,2px) solid rgba(theme.$borderColor, 0.5);
    background: rgba(theme.$theme2, 0.2);
    color: theme.$borderColor;
    padding: 0 $like8px;
    width: $w;
    @include theme.mainFont;

    filter: drop-shadow(2px 2px 2px black);
    

    $h: $inputH;
    height: $h;
    font-size: $inputH*2/3;
    line-height: $inputH;
    box-sizing: border-box;

    margin: calc(50vh - #{$h}/2) auto;
    cursor: pointer;
    transform: scale(1);
    border-radius: $like8px/2;

  }

  .btn--max {
    position: fixed;
    top: calc(50vh - #{$inputH}/2 + #{$inputOverlayButtonHeight} - #{$inputOverlayGutter}/2);
    right: calc(50vw - #{$w}/2 + #{$inputOverlayGutter});
  }
  .btn--back {
    position: fixed;
    top: calc(50vh - #{$inputH} - #{$inputOverlayGutter}/2);
    left: calc(50vw - #{$w}/2);
  }

  .btn--approve, .btn--withdraw {
    position: fixed;
    top: calc(50vh + #{$inputH}/2 + #{$inputOverlayGutter});
    right: calc(50vw - #{$w}/2);
  }

  .btn--deposit {
    position: fixed;
    top: calc(50vh + #{$inputH}/2 +  #{$inputOverlayGutter}*2 + #{$inputOverlayButtonHeight});
    right: calc(50vw - #{$w}/2);
  }

  &__balance, &__poolbalance  {
    position: fixed;
    top: calc(50vh - #{$inputH} + #{$inputOverlayGutter}/2);
    right: calc(50vw - #{$w}/2);
    color: theme.$borderColor;
    font-size: $inputOverlayButtonSize;

  }

  &__allowance {
    position: fixed;
    top: calc(50vh - #{$inputH} - #{$inputOverlayGutter}/2 - #{$inputOverlayButtonSize});
    right: calc(50vw - #{$w}/2);
    color: theme.$borderColor;
    font-size: $inputOverlayButtonSize;
  }


 &.INTENT__theInput--add {
    .btn--approve, .btn--deposit {
      display: block;
    }
    .btn--withdraw {
      display: none;
    }
    .theInput__poolbalance {
      display: none;
    }
    .theInput__balance, .theInput__allowance {
      display: block;
    }
  }
  &.INTENT__theInput--remove {
    .btn--approve, .btn--deposit {
      display: none;
    }
    .btn--withdraw {
      display: block;
    }
    .theInput__poolbalance {
      display: block;
    }
    .theInput__balance, .theInput__allowance {
      display: none;
    }
  }
}


.farm-btn {
  position: absolute;
  top: 8px;

}