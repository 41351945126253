@use "src/_theme.scss";
@use "src/_utils.scss";
@mixin Rpg {
	.convo {
		position: fixed;
		top: 20vh;
		bottom: 20vh;
		left: 20vw;
		right: 20vw;
		background: rgba(0,0,0,0.8);
		border: 1px solid rgba(white,0.5);
	}
	.convo-init--false {
		display: none;
	}

	.statement {
		position: absolute;
		bottom: 16px;
		left: 16px;
		right: 16px;
		height: 50%;
		box-sizing: border-box;
		border: 1px solid rgba(white,0.5);
		background: rgba(black, 0.5);
		color: white;
		padding: 16px;
		font-size: 24px;
		&.speaker-name-- {
			font-style: italic;
		}
	}

	.speaker {
		position: absolute;
		top: calc(50% - 160px);
		left: 16px;
		right: 16px;
		bottom: calc(50% + 16px); 
		text-align: right;
		h3 {
			display: inline-block;
			@include theme.gradGP();
			font-size: 32px;
			vertical-align: bottom;
			margin: 0;
		}
		.speaker-image {
			display: inline-block;
			height: 144px;
			width: 144px;

			position: relative;
			vertical-align: bottom;
			filter: drop-shadow(0 0 1px white);
			overflow:hidden;
			img {
				height: 288px;
				width: 576px;
				position: absolute;
				&.speaker-Solution {
					top: 0;
					left: 0;
				}
				&.speaker-Embel {
					top: 0;
					left: -144px;
				}
			}

		}

	}

	.convo-nav {
		position: absolute;
		bottom: 32px;
		color: white;
		cursor: pointer;
		&--back {
			left: 32px;
		}
		&--next {
			right: 32px;
		}
	}

	.notification {
		display: block;
		position: fixed;
		left: 20vw;
		right: 20vw;
		top: 0;
		bottom:0;
		background: black;
		color: white;
		padding: 32px;
		overflow-y: scroll;

		&--false {
			display: none;
		}

		p {
			font-size: 20px;
		}
		@include utils.scrolly;
	}
	.convo-choice {
		position: relative;
	
		color: white;
		cursor: pointer;
		display:block;
		width: 100%;
		margin: 8px 0;
	}
}
