@use "src/_utils.scss";
@mixin Secret {
	.secret{
		width: 100vw;
		height: 100vh;
		overflow: scroll;
		position: fixed;
		@include utils.scrolly;
		button.shh {
			position: absolute;

			width: 1px;
			height: 1px;
			box-sizing: border-box;
			filter: drop-shadow(0 0 0 black);
			border:none;
			background: white;
			transform: scale(0.5);
		}
		
	&-found--true {
		top: 0;
		left:0;

		.map {
		transform: scale(1);
			img {
				display: block;
			}
		}
	}
	&-found--false {
		&.secret--mapOfTruthMultiverseSector {
			top: 99vh;
			left: 99vw;
		}
		&.secret--solutionsOutpostSector, &.secret--paradoxPubSector {
			top: 100vh;
			left: 100vw;
		}
		
		.map {
			transform: scale(0.1);
			img {
				display: none;
			}
		}
	}
	}

}