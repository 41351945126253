@use "src/_theme.scss";
@use "src/_utils.scss";
@mixin Pool ($PoolWidth) {
.pool {
  $w: $PoolWidth;
  $poolFontSize: 16px;
  $gutter: $poolFontSize;
  position: relative;
  font-size: $poolFontSize;

  display: inline-block;
  margin: 8px 0px;

  box-sizing: border-box;
  
  border-radius: 0;
  width: $w;
  padding: $gutter;
  padding-left: 32px;

  color: theme.$fontColor;

  
  &.poolsvg--false {
    border-top: 8px solid theme.$pink;
    border-bottom: none;
    border-left: none;
    border-right: none;
    background: none;
    &.pool--closed {
      background: rgba(red, 0.6);
      &::after {
        content: "REWARDS ENDED";
        display: inline-block;

      }
      .pool__controls {
        .pool__btn + .pool__btn {
          display: none;
        }
      }

    }

    &.pool--open {
     /* &.poolrate--100 {
        filter: drop-shadow(0 0 8px adjust-hue(adjust-color(theme.$theme, $saturation: 100%), 180deg));
      }
      &.poolrate--50 {
        filter: drop-shadow(0 0 4px adjust-hue(adjust-color(theme.$theme, $saturation: 50%), 135deg));
      }*/
    } 
  }
  
  &__name {
    
    img.moonwasp {
      filter: drop-shadow(0 0 8px theme.$specialFontColor);
    }
  }

  .arrows {
    margin-right: $poolFontSize/2;
    margin-left: $poolFontSize/2;
    color: theme.$effectColor;
  }

  &__textName {
    display: inline-block;
    font-size: $poolFontSize*2.5;
     @include theme.titleFont;
    @include theme.poolTitleFontColor;
    font-weight: 400;
    height: $poolFontSize*5;
    margin-top: 0;
    margin-bottom: $poolFontSize/8;
    a {
      display: inline-block;
      vertical-align: top;
      margin-right: $poolFontSize;
      height: $poolFontSize*5;
    }
    img {
      height: $poolFontSize*5;
      width: auto;
      
  
     
      
    }
    span {
      display: inline-block;
      vertical-align: bottom;
      @include theme.gradGP;
      
    }
  }




&__rewards, &__depositFee, &__balance {
  display: inline-block;
  border-left: 1px solid theme.$pink;
  padding-left: 8px;
  padding-right: 8px;
}

  button {
    @include utils.buttonSizing($poolFontSize);
    @include utils.buttonStyling;

    margin: 2px $poolFontSize/4;
    cursor: pointer;
    transform: scale(1);
    


  
  }

  .pool__controls {
    text-align: right;
    margin-top: 0;
    a {
      display: inline-block;
    text-decoration: none;
   
    font-weight: 600;
    margin-right: 16px;
    @include theme.gradientText(theme.$theme2,theme.$theme3);
    filter: drop-shadow(2px 2px 2px black);
    transform: rotateZ(10deg);
    transition: 0.2s transform ease-in-out;
    &:hover {
      
      transform: rotateZ(0deg);
    }
    & + a {
      margin-left: 16px;
    }
  }
  }

  &__rate {
    position: absolute;
    top: $gutter;
    right: $gutter;
    font-size: $poolFontSize*2/3;
    padding: $poolFontSize/8 $poolFontSize/4;
    background: none;
  }

  .pub-pool-type {
    position: absolute;
    bottom: 0;
    left: 0;
    $w: 180px;
    width: $w;
    
    text-align: center;
    //background: theme.$pink;
    @include theme.gradPG;
    font-size: 40px;
    $h: 40px;
    height: $h;
    transform: rotateZ(-90deg) translateY(-$w/2) translateX(64px);
    @include theme.titleFont;
  }

}

.svg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-16px);
}
svg {position: absolute; width: 100%; height: 100%; overflow: visible; } 
  polygon {width: 100%;}
          stop.stop1 { stop-color: theme.$themeSaturated; }
          .stop2 { stop-color: theme.$theme3; stop-opacity: 0.5; }
          .stop3 { stop-color: theme.$theme2; }

          .stop4 {stop-color: theme.$theme;  stop-opacity: 0.7; }
          .stop5 {stop-color: scale-color(theme.$theme,$lightness:10%);  stop-opacity: 0.7; }

}

