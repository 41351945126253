@use "src/_theme.scss";
@use "src/_utils.scss";
@mixin Ioze ($h) {
	.ioze__word {
		position: relative;
		display: inline-block;
		height: $h;
		vertical-align: middle;

		padding-right: $h/8;
	}

	.ioze__char {
		position: relative;	
		display: inline-block;
		height: $h;
		vertical-align: top;
		img {
	
			display: block;

			height: $h;
			filter: drop-shadow(0 0 2px white);
		}


		&:first-child {
			&.char--pa {

				img {
					filter: drop-shadow(0 0 2px theme.$green);
				}
			}
		}
		
	
	}





}

@mixin IozeSelect($h) {
	.ioze-select {
    position: relative;
    margin: 16px;
    width: $h;
    height: $h;
    overflow-y: scroll;
    display: inline-block;
    vertical-align: top;
    @include utils.scrolly;
    @include Ioze($h - 32px);

    background: rgba(black, 0.4);
    border-top: 1px solid rgba(theme.$pink, 0.5);
    border-left: 1px solid rgba(theme.$green, 0.5);
    border-bottom: 1px solid rgba(theme.$blue, 0.5);
    .opt {
    	cursor: pointer;
    	box-sizing: border-box;
    	width: 100%;
    	position: relative;
    	height: $h;
    	padding: 16px;


      .ioze__char {
      	width: 100%;
    	position: relative;
    	height: $h - 32px;
    	text-align: center;
    	&:hover {
    		background: rgba(theme.$pink, 0.1);
    	}
    	img {
    		height: $h/2;
    		width: $h/2;
    		margin: $h/8 auto;
    	}
    	&:first-child {
			&.char--pa {

				img {
					filter: drop-shadow(0 0 2px white);
				}
			}
		}
      }
    }
    .opt--on {
    	.ioze__char {

    	}
    }
  }

  .ioze-display{
  	 display: inline-block;
  	 width: 80%;
  	 color: white;
  	.ioze__char {
  		width: $h*2;
  		height: $h*2;
  		img {
  			height: $h*2;
  		}
  	}
  }


  span.iozeX {
  	display: inline-block;
  	width: 33%;
  	text-align: right;
  	.ioze-display{
  		width: 100%;
  		display: block;
  	}

  	& + span.iozeX {
  		text-align: center;
  	}

  	& + span.iozeX + span.iozeX {
  		text-align: left;
  	}
  }

  h3.active-name {
  	@include theme.gradBP();
  	display: inline-block;
  	font-size: 40px;
  	text-transform: capitalize;
  }

  table {
  	display: inline-table;
  	vertical-align: top;
  	margin-right: 48px;
  	color: white;
  	border-right: 1px solid theme.$pink;

  	@include theme.mainFont();
  	margin: 0 auto;
  	tr {
  		td {
  			font-size: 20px;
  			text-align: left;
  			padding: 0 8px;
  			width: 128px;
  		}
  		td + td {
  			text-align: right;
  			width: 64px;
  		}
  	}
  }

  .morphing-demo {
  	color: theme.$pink;
  	
  }

  .hexcontainer {
  	display: inline-block;
  	margin-left: 48px;
  	position: relative
  }

  .lab {
  	position: absolute;
  	top: 0;
  	left: 0;
  	color: white;
  }
  .lab-int {
  	left: 44%;
  	top: -22px;
  }
  .lab-sprt {
  	left: 102%;
  	top: 25%;
  }
  .lab-str {
  	left: 80%;
  	top: 92%;
  }
  .lab-clr {
  	left: 0;
  	top: 92%;
  }
  .lab-x {
  	left: -38px;
  	top: 25%;
  }

.charContainer {
	display: inline-block;
	vertical-align: top;
	width: 157px;
	height: 157px;
	border: 1px solid white;

	.animechar {
		position: relative;
		top: 17px;
	}
}


.skin {
	//$mainColor: rgba(255,208,170,1);
	$mainColor: rgba(154,99,68,1);


	@include pink(75%);




	&--a-the, &--a-te {
		@include pink(0%);
	}
	&--a-io, &--ba-the, &--ba-te {
		@include pink(10%);
	}
	&--e-io, &--da-the, &--da-te  {
		@include green(10%);
	}
	&--e-the, &--e-te {
		@include green(0%);
	}
	&--pa-io,, &--ka-the, &--ka-te  {
		@include blue(10%);
	}
	&--pa-the, &--pa-te {
		@include blue(0%);
	}

	&--k {
		fill: mix(theme.$pink, theme.$blue,$weight: 50%);
	}

}



.eye {
	$mainColor: white;
	fill: white;
}

.eyeball {
	$mainColor: scale-color(theme.$blue, $lightness: -30%);
	fill: $mainColor;

	&--the {
		fill: theme.$green;
	}

	&--te {
		fill: theme.$pink;
	}
	&--io, &--e {
		fill: theme.$blue;
	}


}

.charContainer {
	@include utils.bgtextureRadGrad(theme.$pink,theme.$green);
}

#head {
	filter: drop-shadow(0 2px 2px black);
}
#robe {
	filter: drop-shadow(0 2px 4px black);
}
.hair {
	fill: #dee4d9;
	stroke-width: 0.1;
	stroke: black;


}
.robe {
	fill: black;
	stroke: black;
	stroke-width: 2;
	&__lining {
		fill: transparent;
		stroke-width: 2;
		stroke: theme.$pink;
	}

	&__back {
		fill: black;
	}
}

}
@mixin mixer($color, $p) {
	fill: mix(rgba(154,99,68,1), $color,$weight: $p);
}

@mixin pink( $p) {
	@include mixer(theme.$pink,$p);
}

@mixin green( $p) {
	@include mixer(theme.$green,$p);
}
@mixin blue( $p) {
	@include mixer(theme.$blue,$p);
}


.char--aete, .char--aethe, .char--aathe, .char--aate {
	.hair {
		fill: mix(#dee4d9, theme.$pink, $weight: 50%);
	}
	
}

.char--eete, .char--eethe, .char--eathe, .char--eate {
	.hair {
		fill: mix(#dee4d9, theme.$green, $weight: 70%);
	}
	
}

.char--paete, .char--paethe, .char--paathe, .char--paate {
	.hair {
		fill: mix(#dee4d9, theme.$blue, $weight: 50%);
	}
	
}